.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.letter {
  display: block;
  padding: .5em 1em;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 .5em;
}

.letter--used {
  opacity: .25;
  text-decoration: line-through;
}
